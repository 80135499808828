// Media Query
@mixin mq($bp) {
  @media screen and (max-width: #{map-get($breakpoint, $bp)}) {
   @content;
  }
}

// pxから%を計算して返す
@function getPercent($target-px, $wrap-px) {
  @return calc($target-px / $wrap-px * 100%);
}
// pxから%(VW)を計算して返す
@function getVW($target-px, $wrap-px) {
  @return calc($target-px / $wrap-px * 100vw);
}
// pxから%(hw)を計算して返す
@function getVH($target-px, $wrap-px) {
  @return calc($target-px / $wrap-px * 100vh);
}
@function getVW-base($target-px) {
  @return calc(#{$target-px} / #{$base-width} * 100vw);
}

// font
// font-family interstate normal:500 bold:700
@mixin ff-en($weight : regular) {
  font-family: "interstate", sans-serif;
  @if $weight == "bold" {
    font-weight: 700;
  } @else {
    font-weight: 500;
  }
}
// font-size rem
@mixin fz($size: 16) {
  font-size: calc($size / 10) + rem;
}
// font-family interstate normal:500 bold:700
@mixin fw($weight) {
  @if $weight == "bold" {
    font-weight: 600;
  } 
  @else if $weight == "sin" {
    font-weight: 300;
  } 
  @else {
    font-weight: 500;
  }
}