@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;500;600&display=swap");

html,
body {
  font-family: "YakuHanJP", "Noto Sans JP", "游ゴシック体", "YuGothic",
    "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
  font-weight: 500;
  height: 100%;
  line-height: 1.8;
}
html {
  font-size: 62.5%;
}
body {
  @include fz(16);
}
a {
  color: $color-black;
  text-decoration: none;
}

// border frame
html:before,
html:after,
body:before,
body:after {
  content: "";
  background: $color-black;
  position: fixed;
  display: block;
  z-index: $zindex-border-frame;
}
html {
  &:before {
    height: 10px;
    width: 100vw;
    left: 0;
    top: 0;
  }
  &:after {
    width: 10px;
    height: 100svh;
    right: 0;
    top: 0;
  }
}
body {
  &:before {
    height: 10px;
    width: 100vw;
    bottom: 0;
    left: 0;
  }
  &:after {
    width: 10px;
    height: 100svh;
    top: 0;
    left: 0;
  }
}

// Layout
.cts {
  &__inner {
    margin: 0 auto;
    max-width: 1076px;
    @include mq(xl) {
      padding: 0 30px;
    }
  }
  &__t-space-l {
    padding-top: 100px;
  }
  &__tb-space-l {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  &__t-space-m {
    padding-top: 80px;
    @include mq(sm) {
      padding-top: 50px;
    }
    .cts__h2-en {
      margin-bottom: 50px;
      @include mq(sm) {
        margin-bottom: 30px !important;
      }
    }
  }
  &__tb-space-m {
    padding-top: 80px;
    padding-bottom: 80px;
    @include mq(sm) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .cts__h2-en {
      margin-bottom: 50px;
      @include mq(sm) {
        margin-bottom: 30px !important;
      }
    }
  }
  &__h2-en {
    @include ff-en(bold);
    @include fz(60);
    margin-bottom: 50px;
    line-height: 1.4;
    @include mq(md) {
      @include fz(44);
    }
    span {
      @include fz(25);
      display: inline-block;
      padding-bottom: 0;
      @include mq(md) {
        @include fz(20);
        padding-bottom: 0;
      }
    }
    &.top-line {
      padding-top: 84px;
      position: relative;
      &:before {
        content: "";
        width: 100px;
        height: 1px;
        background-color: $color-black;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
  &__h2-en-info {
    margin-top: -50px;
    margin-bottom: 50px;
    text-align: center;
    @include fz(25);
    @include mq(sm) {
      @include fz(20);
      margin-bottom: 50px;
    }
  }
}

// content style
.nav {
  width: 376px;
  height: 100%;
  height: 100svh;
  background-color: $color-black;
  position: fixed;
  top: 0;
  right: -120%;
  transition: all 0.3s;
  z-index: $zindex-nav;
  &.panelactive {
    right: 0;
  }
  &__inner {
    position: relative;
    height: 100%;
    height: 100svh;
    color: $color-white;
    padding: 60px;
    a {
      color: $color-white;
    }
  }
  &__close {
    position: absolute;
    top: 30px;
    right: 36px;
    cursor: pointer;
    img {
      width: 30px;
      height: auto;
    }
  }
  .gmenu {
    &__list {
      &__item {
        margin-bottom: 15px;
        @include mq(sm) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .sns-list {
    position: absolute;
    bottom: 60px;
    gap: 0 18px;
    @include mq(sm) {
      bottom: 50px;
    }
  }
}
.fix {
  &-logo {
    position: fixed;
    top: 30px;
    left: 23px;
    z-index: $zindex-fix-btn;
    img {
      width: 60px;
      height: auto;
    }
    @include mq(sm) {
      top: 18px;
      left: 18px;
      img {
        width: 37px;
      }
    }
  }
  &-ctl {
    position: fixed;
    top: 30px;
    right: 23px;
    z-index: $zindex-fix-btn;
    width: 85px;
    color: $color-white;
    @include mq(sm) {
      top: 18px;
      right: 18px;
      width: 75px;
    }
    &__menu {
      text-align: center;
      background-color: $color-gold;
      @include ff-en(bold);
      @include fz(24);
      cursor: pointer;
      line-height: 39px;
      @include mq(sm) {
        @include fz(20);
        line-height: 32px;
      }
    }
    &__lang {
      margin-top: 4px;
      background-color: $color-gold;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 7px;
      @include mq(sm) {
        display: none;
      }
      a {
        display: block;
        text-align: center;
        @include ff-en(bold);
        @include fz(14);
        color: $color-white;
        padding: 0 2px;
        line-height: 20px;
        &.active {
          color: $color-gold;
          background-color: $color-white;
        }
      }
    }
    .bogo-language-switcher{
      margin-top: 4px;
      background-color: $color-gold;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      justify-content: space-between;
      
      padding: 7px;
      @include mq(sm) {
        display: none;
      }
      li{
        display: block;
        text-align: center;
        @include ff-en(bold);
        @include fz(14);
        color: $color-white;
        padding: 0 2px;
        line-height: 20px;
        a{
          color: $color-white;
        }
        &.current {
          background-color: $color-white;
          a{
            color: $color-gold;
          }
        }
      }
    }
  }
  &-shop {
    position: fixed;
    bottom: 30px;
    right: 23px;
    z-index: $zindex-fix-btn;
    @include mq(sm) {
      bottom: 13px;
      right: 13px;
    }
    a {
      display: flex;
      width: 100px;
      height: 100px;
      background-color: $color-gold;
      color: $color-white;
      border-radius: 50px;
      line-height: 1;
      text-align: center;
      justify-content: center;
      align-items: center;
      @include mq(sm) {
        width: 80px;
        height: 80px;
        border-radius: 40px;
      }
    }
    &__inner {
      @include ff-en(bold);
      @include fz(20);
      @include mq(sm) {
        @include fz(14);
      }
    }
  }
}
.news-list {
  &__item {
    border-bottom: 1px solid $color-black;
    margin-bottom: 30px;
    @include mq(md) {
      margin-bottom: 20px;
    }
    a {
      display: block;
    }
    time {
      @include fz(14);
      @include fw(sin);
    }
    p {
      @include fz(20);
      padding: 18px 0;
      font-weight: 600;
      @include mq(md) {
        padding: 14px 0 20px;
      }
    }
  }
  &__link {
    margin-top: 50px;
    text-align: right;
  }
}
.restaurant-list {
  &__item {
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    // @include mq(md) {
    //   height: auto;
    //   padding-top: 300px;
    //   background-size: auto 100%;
    //   background-position: top center;
    // }
    @include mq(sm) {
      height: auto;
      padding-top: 100vw;
      background-size: auto 100vw;
      background-position: top center;
    }
    &__inner {
      background-color: $color-black;
      color: $color-white;
      padding: 55px;
      position: absolute;
      a {
        color: $color-white;
      }
      @include mq(lg) {
        padding: 30px;
        width: 480px !important;
      }
      @include mq(sm) {
        position: static;
        width: 100% !important;
        padding: 50px;
      }
    }
    &__ttl {
      @include fz(30);
      margin-bottom: 40px;
      line-height: 1.6;
      @include mq(lg) {
        @include fz(26);
        margin-bottom: 25px;
      }
      @include mq(sm) {
        @include fz(26);
        margin-bottom: 25px;
      }
    }
    &__link {
      margin-top: 50px;
      @include mq(lg) {
        margin-top: 25px;
      }
      @include mq(md) {
        margin-top: 25px;
      }
    }
    &:nth-child(1) {
      background-image: url(../img/bg_restaurant01.jpg);
      .restaurant-list__item__inner {
        width: 515px;
        right: 0;
        bottom: 0;
      }
      @include mq(sm) {
        background-image: url(../img/bg_restaurant01@sp.jpg);
      }
    }
    &:nth-child(2) {
      background-image: url(../img/bg_restaurant02.jpg);
      .restaurant-list__item__inner {
        width: 568px;
        left: 0;
        bottom: 0;
      }
      @include mq(sm) {
        background-image: url(../img/bg_restaurant02@sp.jpg);
      }
    }
    &:nth-child(3) {
      background-image: url(../img/bg_restaurant03.jpg);
      .restaurant-list__item__inner {
        width: 571px;
        right: 0;
        bottom: 0;
      }
      @include mq(sm) {
        background-image: url(../img/bg_restaurant03@sp.jpg);
      }
    }
  }
}
.available-location{
  &__list{
    &__item{
      border-bottom: 1px solid $color-gold;
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      @include mq(sm) {
        display: block;
      }
      &__cate{
        width: 180px;
        color: $color-gold;
        @include mq(sm) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      &__content{
        flex: 1;
        &__innr{
          display: flex;
          @include mq(sm) {
            display: block;
          }
        }
      }
      &__area{
        width: 110px;
      }
      &__body{
        padding-bottom: 20px;
        &__innr{
          display: flex;
          @include mq(sm) {
            display: block;
          }
        }
      }
      &__name{
        padding-right: 40px;
      }
      &__address{

      }
    }
  }
}
.footer {
  background-color: $color-black;
  color: $color-white;
  .cts__inner {
    max-width: 859px;
  }
  a {
    color: $color-white;
  }
  &__logo {
    text-align: center;
    padding: 80px 0;
    img {
      width: 203px;
      height: auto;
    }
    @include mq(sm) {
      img {
        width: 157px;
      }
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    @include fz(20);
    margin-bottom: 50px;
    @include mq(sm) {
      display: block;
      &.cts__inner {
        padding: 0 65px;
      }
    }
  }
  &__sns {
    margin-bottom: 32px;
  }
  &__bottom {
    border-top: 2px solid $color-white;
    padding: 32px 0;
    @include mq(sm) {
      border: none;
    }
    &__list {
      line-height: 1;
      display: flex;
      justify-content: center;
      @include mq(md) {
        flex-wrap: wrap;
      }
      &__item {
        @include ff-en();
        padding: 0 30px;
        border-right: 1px solid $color-white;
        @include mq(md) {
          @include fz(14);
        }
        &:first-child {
          padding-left: 0;
          @include mq(md) {
            width: 100%;
            @include fz(10);
            text-align: center;
            padding: 0;
            margin-bottom: 30px;
          }
        }
        &:nth-child(2) {
          @include mq(md) {
            padding-left: 0;
          }
        }
        &:last-child {
          padding-right: 0;
          border: none;
        }
      }
    }
  }
}
// top contents
.top {
  &-mv {
    height: 100%;
    height: 100svh;
    position: relative;
    background-image: url(../img/mv.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    @include mq(sm) {
      background-image: url(../img/mv@sp.jpg);
    }
    &__cts {
      position: absolute;
      //top: getVW(175, $base-width-pc);
      top: 50%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      @include mq(lg) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &__logo {
        margin-bottom: getVW(40, $base-width-pc);
        img {
          width: 340px;
          height: auto;
        }
        @include mq(sm) {
          margin-bottom: getVW(65, $base-width-sp);
          img {
            width: 227px;
          }
        }
      }
      &__txt {
        @include fz(60);
        @include ff-en(bold);
        color: $color-white;
        line-height: 1.2;
        @include mq(md) {
          @include fz(50);
          line-height: 1.6;
        }
        @include mq(sm) {
          @include fz(35);
        }
      }
    }
  }
  &-lead {
    background-color: $color-black;
    color: $color-white;
    padding: 80px 0;
    &__ttl {
      @include ff-en(bold);
      @include fz(73);
      line-height: calc(88 / 77);
      @include mq(sm) {
        @include fz(30);
      }
    }
    &__txt {
      @include fz(20);
      margin: 60px 0;
      line-height: calc(40 / 20);
      @include mq(sm) {
        @include fz(18);
        margin: 50px 0;
        line-height: calc(38 / 18);
      }
    }
    &__link {
      text-align: right;
    }
  }
  &-recruit {
    background-color: $color-gray;
    &__img {
      display: flex;
      justify-content: space-between;
      img {
        width: 49%;
      }
      @include mq(sm) {
        flex-direction: column;
        gap: 10px 0;
        img {
          width: 100%;
        }
      }
    }
    &__link {
      text-align: right;
      margin-top: 60px;
      @include mq(sm) {
        margin-top: 40px;
      }
    }
  }
  &-access {
    background-color: $color-gray;
    &__col {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 60px;
      @include mq(lg) {
        gap: 0 40px;
      }
      @include mq(md) {
        display: block;
      }
    }
    &__map {
      border: 1px solid #ccc;
      iframe {
        width: 627px;
        height: 390px;
        -webkit-filter: saturate(150%);
        filter: saturate(150%);
        @include mq(lg) {
          width: 500px;
        }
        @include mq(md) {
          width: 100%;
        }
      }
      @include mq(md) {
        margin-bottom: 30px;
      }
    }
    &__data {
      flex: 1;
      &__address {
        margin-bottom: 40px;
        @include mq(md) {
          margin-bottom: 20px;
        }
      }
      & > dl {
        & > dt {
          @include fz(25);
          margin-bottom: 10px;
          @include mq(md) {
            @include fz(20);
          }
        }
        & > dd {
          @include fz(20);
          @include mq(md) {
            @include fz(16);
          }
          & > dl {
            display: flex;
            margin-bottom: 3px;
            & > dt {
              width: 120px;
            }
            & > dd {
              width: calc(100% - 120px);
              span{
                font-size: 90%;
              }
            }
          }
        }
      }
    }
  }
  &-contact {
    margin-top: 160px;
    color: $color-white;
    text-align: center;
    @include mq(sm) {
      margin-top: 100px;
    }
    .cts__inner {
      padding: 60px 0;
      background-color: $color-black;
      @include mq(lg) {
        margin: 0 30px;
      }
      @include mq(sm) {
        padding: 40px 0;
      }
    }
    &__ttl {
      @include ff-en(bold);
      @include fz(45);
      margin-bottom: 10px;
      @include mq(sm) {
        margin-bottom: 20px;
      }
    }
    &__txt {
      @include fz(20);
      margin-bottom: 40px;
      @include mq(sm) {
        @include fz(16);
      }
    }
    &__link {
      text-align: center;
      a {
        display: inline-block;
        width: 236px;
        line-height: 56px;
        color: $color-white;
        border: 1px solid $color-white;
        @include mq(sm) {
          width: 211px;
        }
      }
    }
  }
  &-gallery {
    &-middle {
      padding: 100px 0;
      .cts__inner {
        position: relative;
        height: 820px;
        @include mq(lg) {
          width: getVW(1076, $base-width-pc);
          height: getVW(820, $base-width-pc);
        }
        @include mq(sm) {
          width: auto;
          height: 780px;
        }
      }
      img {
        position: absolute;
        height: auto;
      }
    }
    &-bottom {
      position: relative;
      height: 1188px;
      img {
        position: absolute;
        height: auto;
      }
      @include mq(lg) {
        height: getVW(1188, $base-width-pc);
      }
      @include mq(sm) {
        height: 900px;
      }
    }
    &__txt01 {
      width: 172px;
      top: 420px;
      left: 44px;
      @include mq(lg) {
        width: getVW(172, $base-width-pc);
        top: getVW(420, $base-width-pc);
        left: getVW(44, $base-width-pc);
      }
      @include mq(sm) {
        display: none;
      }
    }
    &__img01 {
      width: 538px;
      top: 0;
      left: 0;
      @include mq(lg) {
        width: getVW(538, $base-width-pc);
      }
      @include mq(sm) {
        width: 280px;
      }
    }
    &__img02 {
      width: 458px;
      top: 105px;
      right: 0;
      @include mq(lg) {
        width: getVW(458, $base-width-pc);
        top: getVW(105, $base-width-pc);
      }
      @include mq(sm) {
        width: 238px;
        top: 209px;
      }
    }
    &__img03 {
      width: 342px;
      left: 196px;
      bottom: 0;
      @include mq(lg) {
        width: getVW(342, $base-width-pc);
        left: getVW(196, $base-width-pc);
      }
      @include mq(sm) {
        width: 238px;
        left: 30px;
        bottom: 0;
      }
    }
    &__img04 {
      width: 714px;
      top: 100px;
      left: 50%;
      margin-left: -357px;
      //transform: translateX(-50%);
      @include mq(lg) {
        width: getVW(714, $base-width-pc);
        top: getVW(100, $base-width-pc);
        margin-left: calc(getVW(714, $base-width-pc) / 2 * -1);
      }
      @include mq(sm) {
        width: 256px;
        top: 100px;
        left: 0;
        margin-left: 0;
        transform: translate(0, 0);
      }
    }
    &__img05 {
      width: 538px;
      bottom: 199px;
      left: -80px;
      @include mq(lg) {
        width: getVW(538, $base-width-pc);
        bottom: getVW(199, $base-width-pc);
      }
      @include mq(sm) {
        width: 256px;
        bottom: 396px;
        left: auto;
        right: 0;
      }
    }
    &__img06 {
      width: 634px;
      bottom: 125px;
      right: 0;
      @include mq(lg) {
        width: getVW(634, $base-width-pc);
        bottom: getVW(125, $base-width-pc);
      }
      @include mq(sm) {
        width: 300px;
        bottom: 100px;
        left: 30px;
        right: auto;
      }
    }
  }
  &-beer {
    margin-bottom: 100px;
    &__head {
      height: 640px;
      position: relative;
      background-image: url(../img/bg_beer01.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-bottom: 80px;
      @include mq(sm) {
        background-image: url(../img/bg_beer01@sp.jpg);
        margin-bottom: 50px;
      }
      &__ttl {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color-white;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 50px 30px;
      @include mq(sm) {
        width: 100%;
      }
      &__item {
        width: calc((100% - 60px) / 3);
        @include mq(md) {
          width: calc((100% - 30px) / 2);
        }
        @include mq(sm) {
          width: 100%;
        }
        &__th {
          img {
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 1;
            object-fit: cover;
          }
        }
        &__name {
          margin-top: 10px;
          span {
            @include fz(16);
          }
          @include ff-en(bold);
          @include fz(25);
        }
      }
    }
    &__link {
      text-align: right;
      margin-top: 60px;
    }
  }
  &-beer-line {
    height: 570px;
    position: relative;
    overflow: hidden;
    @include mq(md) {
      height: 460px;
    }
    @include mq(sm) {
      height: 325px;
    }
    &__img {
      width: 154px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: $zindex-top-beer-img;
      @include mq(md) {
        width: 130px;
      }
      @include mq(sm) {
        width: 87px;
      }
    }
    &__wrap {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      z-index: $zindex-top-beer-txt;
      &__inner {
        width: 100%;
      }
    }
    &__txt {
      @include ff-en(bold);
      @include fz(100);
      display: flex;
      @include mq(md) {
        @include fz(60);
      }
      @include mq(sm) {
        @include fz(40);
      }
      div {
        flex: 0 0 auto;
        white-space: nowrap;
        padding: 0 40px;
      }
      .loop1 {
        animation: beer-txt-loop 30s linear infinite;
      }
      .loop2 {
        position: absolute;
        top: 0;
        left: 0;
        animation: beer-txt-loop 30s -15s linear infinite;
      }
    }
    @keyframes beer-txt-loop {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
  &-house {
    background-color: $color-black;
    padding: 80px 0;
    @include mq(sm) {
      margin-bottom: 60px;
    }
    &__ttl {
      text-align: center;
      color: $color-white;
      margin-bottom: 80px;
      @include ff-en(bold);
      @include fz(25);
      @include mq(sm) {
        margin-bottom: 50px;
        @include fz(20);
      }
    }
    &__slide {
      width: 100%;
      margin: 0 auto;
      position: relative;
      @include mq(lg) {
        width: 95%;
      }
      &__inner {
        width: 100%;
        overflow: hidden;
      }
      .swiper-slide {
        width: 100%;
        aspect-ratio: 331 / 561;
        background-color: #DDD;
        img {
          object-fit: cover;
          vertical-align: bottom;
          width: 100%;
          height: 100%;
        }
        @include mq(sm) {
          aspect-ratio: 300 / 541;
        }

      }
      &__next,
      &__prev {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: $zindex-slider-btn;
        cursor: pointer;
        img {
          width: 28px;
          height: auto;
        }
      }
      &__prev {
        left: -14px;
      }
      &__next {
        right: -14px;
      }
    }
  }
  &-local {
    margin-bottom: 100px;
    overflow: hidden;
    @include mq(sm) {
      margin-bottom: 60px;
    }
    &__slide {
      width: 100%;
      margin: 0 auto;
      position: relative;
      .swiper-slide {
        width: 100%;
        aspect-ratio: 1 / 1;
        padding-bottom: 95px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &__txt {
        position: absolute;
        margin: 0 30px;
        padding: 15px;
        bottom: 0;
        background-color: $color-white;
        border: 1px solid $color-black;
        z-index: calc($zindex-slider-btn + 1);
      }
      &__next,
      &__prev {
        position: absolute;
        bottom: 40px;
        //transform: translate(0, -50%);
        z-index: $zindex-slider-btn;
        cursor: pointer;
        img {
          width: 14px;
          height: auto;
        }
      }
      &__prev {
        left: 25%;
      }
      &__next {
        right: 25%;
      }
      @include mq(md) {
        &__prev {
          left: 8%;
        }
        &__next {
          right: 8%;
        }
      }
      @include mq(sm) {
        &__prev {
          left: 2.5%;
        }
        &__next {
          right: 2.5%;
        }
      }
    }
  }
}

// lower page
.mv {
  height: 566px;
  margin-bottom: 122px;
  position: relative;
  background-color: $color-gray;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mq(sm) {
    height: auto;
    margin-bottom: 60px;
    background-position: center top;
    background-size: auto 100vw;
  }
  &.about {
    background-image: url(../img/bg_about.jpg);
    @include mq(sm) {
      background-image: url(../img/bg_about@sp.jpg);
    }
  }
  &.beer {
    background-image: url(../img/bg_beer.jpg);
    @include mq(sm) {
      background-image: url(../img/bg_beer@sp.jpg);
    }
  }
  &.restaurant {
    background-image: url(../img/bg_restaurant.jpg);
    @include mq(sm) {
      background-image: url(../img/bg_restaurant@sp.jpg);
    }
  }
  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $color-white;
    width: 90%;
    @include mq(sm) {
      position: static;
      transform: translate(0, 0);
      width: 100%;
    }
  }
  &__ttl {
    @include ff-en(bold);
    @include fz(60);
    @include mq(sm) {
      position: static;
      transform: translate(0, 0);
      text-align: center;
      @include fz(46);
      line-height: 100vw;
    }
  }
  &__txt {
    @include fz(25);
    @include mq(sm) {
      color: $color-black;
      @include fz(20);
      padding: 30px 30px 0;
      background-color: $color-white;
    }
  }
}
.page {
  .team {
    margin-bottom: 85px;
    @include mq(md) {
      margin-bottom: 60px;
    }
    .cts__inner {
      @include mq(md) {
        padding: 0;
      }
    }
    &-list {
      &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 40px;
        @include mq(lg) {
          margin-bottom: 50px;
        }
        &__th {
          width: 565px;
          margin-right: 80px;
          img {
            width: 100%;
          }
          @include mq(lg) {
            width: 480px;
            margin-right: 30px;
          }
          @include mq(md) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
        &__data {
          flex: 1;
          @include mq(md) {
            padding: 0 30px;
          }
          &__name {
            @include fz(25);
            margin: 10px 0;
            @include mq(md) {
              @include fz(20);
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
  .history {
    .cts__inner {
      @include mq(md) {
        padding: 0;
      }
    }
    &__cts-wrap {
      .history__cts:nth-child(even) {
        @include mq(md) {
          flex-direction: column-reverse;
        }
      }
    }
    &__cts {
      display: flex;
      flex-wrap: wrap;
      gap: 0 33px;
      margin-bottom: 50px;
      @include mq(md) {
        margin-bottom: 60px;
      }
      &__th {
        width: 538px;
        img {
          width: 100%;
        }
        @include mq(lg) {
          width: 50%;
        }
        @include mq(md) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
      &__txt {
        flex: 1;
        @include mq(md) {
          width: 100%;
          padding: 0 30px;
        }
      }
    }
  }
  .menu {
    &__wrap {
      margin-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      gap: 0 2%;
      word-wrap: break-all;
      @include mq(sm) {
        margin-bottom: 60px;
      }
    }
    &__col {
      &__full {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0 45px;
      }
      &__half {
        width: 48%;
        margin: 25px 0;
        @include mq(md) {
          margin: 15px 0;
        }
        @include mq(sm) {
          width: 100%;
        }
      }
      &__th {
        img {
          object-fit: cover;
          width: 537px;
          height: 325px;
        }
        @include mq(lg) {
          img{
            width: 480px;
            aspect-ratio: 8 / 5;
          }
        }
        @include mq(md) {
          img{
            width: 100%;
          }
          margin-bottom: 10px;
        }
      }
      &__data {
        flex: 1;
        @include ff-en(bold);
        &__name {
          @include fz(25);
          margin-bottom: 0px;
          @include mq(md) {
            @include fz(20);
          }
        }
        &__price {
          @include fz(25);
          @include mq(md) {
            @include fz(20);
          }
        }
        &__txt {
          margin-top: 10px;
          @include mq(sm) {
            margin-top: 0;
          }
        }
      }
    }
    &__head {
      @include ff-en(bold);
      margin-bottom: 25px;
      &__ttl {
        padding-left: 16px;
        border-left: solid 17px $color-black;
        @include fz(35);
        margin-bottom: 10px;
        line-height: 1.2;
        @include mq(sm) {
          padding-left: 10px;
          border-left: solid 14px $color-black;
          @include fz(25);
        }
      }
    }
  }
  .party {
    text-align: center;
    &__img {
      margin-bottom: 50px;
      img {
        width: 100%;
      }
    }
    &__txt01 {
      @include fz(35);
      margin-bottom: 40px;
      @include mq(sm) {
        margin-bottom: 20px;
        @include fz(25);
      }
    }
    &__txt02 {
      @include fz(20);
      margin-bottom: 40px;
      @include mq(sm) {
        margin-bottom: 20px;
        text-align: left;
        @include fz(20);
      }
    }
    &__txt03 {
      @include fz(25);
    }
  }
  .contact {
    margin-bottom: 100px;
    &.head {
      padding-top: 85px;
      @include mq(sm) {
        padding-top: 100px;
      }
    }
    &__body {
      @include fz(20);
    }
    &__info{
      text-align: center;
      margin-bottom: 30px;
      @include mq(sm){
        text-align: left;
      }
      &__txt{
        margin-bottom: 2rem;
        word-break: break-all;
      }
      a:hover{
        text-decoration: underline;
      }
    }
    &__form {
      border: 1px solid $color-gray-dark;
      border-radius: 20px;
      padding: 80px 80px 30px;
      @include mq(md){
        padding: 60px 30px 30px;
      }
      dl {
        display: flex;
        flex-wrap: wrap;
        dt {
          width: 268px;
          padding: 5px 0;
        }
        dd {
          width: calc(100% - 268px);
          margin-bottom: 50px;
          input, textarea{
            width: 100%;
            padding: 5px 10px;
          }
          textarea{
            height: 346px;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
        @include mq(md){
          display: block;
          dt,dd{
            width: 100%;
          }
          dt{
            margin-bottom: 5px;
          }
          dd{
            margin-bottom: 20px;
          }
        }
      }
    }
    &__submit{
      text-align: center;
      margin-top: 90px;
      // input[type=checkbox]{
      //   border-radius: 8px;
      // }
      @include mq(sm){
        margin-top: 40px;
      }
      &__col{
        margin-bottom: 30px;
        a{
          text-decoration: underline;
        }
        @include mq(sm){
          @include fz(14);
          margin-bottom: 40px;
        }
      }
    }
    input[type=text],input[type=tel],input[type=email], textarea {
      border: 1px solid $color-gray-dark;
      border-radius: 12px;
      @include fz(20);
      padding: 10px 20px;
    }
    &__thanks{
      &__txt{
        text-align: center;
        margin: 95px 0;
        @include mq(sm){
          @include fz(16);
          margin: 75px 0;
        }
      }
      &__logo{
        text-align: center;
        margin-left: 110px;
        img{
          width: 260px;
        }
        @include mq(sm){
          margin-left: 50px;
          img{
            width: 170px;
          }
        }
      }
    }
  }
}
.error404{
  .cts{
    &.head{
      padding-top: 85px;
      @include mq(sm) {
        padding-top: 100px;
      }
    }
  }
}

// wprdpress post
.news {
  &.head {
    padding-top: 85px;
    @include mq(sm) {
      padding-top: 100px;
    }
  }
  &__article {
    margin-bottom: 180px;
    @include mq(md) {
      margin-bottom: 80px;
    }
    &__head {
      @include ff-en(bold);
    }
    &__time {
      @include fz(25);
      @include mq(sm) {
        @include fz(20);
      }
    }
    &__ttl {
      @include fz(35);
      margin: 60px 0;
      @include mq(md) {
        margin: 40px 0;
      }
      @include mq(sm) {
        @include fz(25);
        margin: 30px 0;
      }
    }
    &__th {
      margin-bottom: 50px;
      img {
        width: 100%;
      }
      @include mq(md) {
        margin-bottom: 30px;
      }
    }
    &__body {
      p {
        margin-bottom: 2rem;
      }
    }
  }
}
.beer-list {
  &__item {
    display: flex;
    flex-wrap: wrap;
    gap: 0 35px;
    margin-bottom: 130px;
    &__th {
      width: 547px;
      img {
        width: 100%;
      }
      @include mq(lg) {
        width: 400px;
      }
      @include mq(md) {
        width: 300px;
      }
      @include mq(sm) {
        width: 100%;
      }
    }
    &__data {
      flex: 1;
      &__ttl {
        @include ff-en(bold);
        @include fz(35);
        margin-bottom: 36px;
        @include mq(md) {
          margin-bottom: 10px;
        }
        @include mq(sm) {
          @include fz(25);
          margin: 15px 0;
        }
      }
      &__txt {
        margin-bottom: 40px;
        @include mq(md) {
          margin-bottom: 20px;
        }
        @include mq(sm) {
          margin-bottom: 30px;
        }
      }
      &__detail {
        border: 1px solid $color-black;
        &-btn {
          display: none;
          cursor: pointer;
          @include mq(sm) {
            display: block;
            text-align: center;
            line-height: 30px;
          }
        }
        &__inner {
          @include mq(sm) {
            display: none;
          }
          padding: 16px;
          dl {
            display: flex;
            flex-wrap: wrap;
            dt {
              width: 175px;
              @include mq(sm) {
                width: 100%;
              }
            }
            dd {
              padding-left: 10px;
              margin-bottom: 3px;
              width: calc(100% - 175px);
              @include mq(sm) {
                width: 100%;
              }
            }
          }
        }
      }
      &__link {
        margin-top: 40px;
        text-align: right;
        a {
          @include mq(md) {
            width: 280px;
          }
        }
      }
    }
  }
}

.wp{
  &-block{
    &-heading{
      margin: 30px 0 10px;
    }
  }
}

.grecaptcha-badge { visibility: hidden; }