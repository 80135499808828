// ブレイクポイント
// モバイルを基準 min-widthの指定
// 〇〇px以上で切り替わる
// $breakpoint: (
//   xs: 0,
//   sm: 575px,
//   md: 767px,
//   lg: 1024px,
//   xl: 1119px,
//   xxl: 1399px
// );
// ブレイクポイント
// モバイルを基準 min-widthの指定
// 〇〇px以下で切り替わる
$breakpoint: (
  xs: 0,
  sm: 575px,
  md: 768px,
  lg: 1024px,
  xl: 1119px,
  xxl: 1399px
);


// デザイン上の横幅（比率計算で使用のためpx単位なし）
$base-width-pc: 1366;
$base-width-sp: 375;

//画像フォルダ
$img_path: "/img/";

//カラー設定
$color-gold: #D5A026;
$color-gray: #F2F3F4;
$color-white: #FFFFFF;
$color-black: #000000;
$color-gray-dark: #707070;

//zindex
$zindex-loading: 9999;
$zindex-silder-pagenation: 101;
$zindex-sidemenu: 100;
$zindex-nav: 15;
$zindex-border-frame: 10;
$zindex-fix-btn: 5;
$zindex-slider-btn: 3;
$zindex-top-beer-img: 2;
$zindex-top-beer-txt: 1;