.btn {
  &-arrow {
    &-r {
      display: inline-block;
      text-align: left;
      width: 282px;
      padding-bottom: 14px;
      &-w {
        color: $color-white;
        background: url(../img/arrow_bottom_r_w.png) no-repeat;
        background-position: right bottom;
        background-size: 282px auto;
      }
      &-b {
        color: $color-black;
        background: url(../img/arrow_bottom_r_b.png) no-repeat;
        background-position: right bottom;
        background-size: 282px auto;
      }
      @include mq(md) {
        width: 227px;
        &-w {
          background-size: 227px auto;
        }
      }
    }
  }
  &-square {
    &-b {
      background: $color-black;
      color: $color-white;
      padding: 20px 40px;
      @include mq(sm) {
        @include fz(16);
      }
    }
  }
}
.gmenu {
  &__list {
    &__item {
      @include fz(20);
      line-height: 30px;
      margin-bottom: 10px;
      @include ff-en();
      &-indent {
        padding-left: 40px;
        @include fz(18);
      }
    }
  }
}
.sns-list {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 40px;
  &__item {
    img {
      vertical-align: middle;
      height: auto;
    }
    &__x {
      width: 32.45px;
    }
    &__instagram {
      width: 32.45px;
    }
    &__note {
      width: 88.46px;
    }
    &__facebook {
      width: 32.45px;
    }
  }
  @include mq(sm) {
    gap: 0 20px;
  }
}

.wp-pagenavi {
  display: flex;
  justify-content: center;
  line-height: 1.3;
  .current,
  .page,
  .previouspostslink,
  .nextpostslink {
    @include fz(20);
    @include ff-en();
    display: block;
    margin: 0 10px;
    @include mq(sm) {
      @include fz(16);
      margin: 0 10px;
    }
  }
  .current {
    @include ff-en(bold);
    border-bottom: 2px solid $color-black;
  }
  .page {
  }
  .previouspostslink {
  }
  .nextpostslink {
  }
}

.fadein {
  opacity : 0;
  transform : translate(0, 100px);
  transition : all 1s;

}
 
.fadein.active{
  opacity : 1;
  transform : translate(0, 0);
}