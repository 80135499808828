.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }
.mb80 { margin-bottom: 80px; }
.mb100 { margin-bottom: 100px; }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.pl10{ padding-left: 10px; }
.pl20{ padding-left: 20px; }
.pl30{ padding-left: 30px; }
.pl40{ padding-left: 40px; }
.pl50{ padding-left: 50px; }
.pr10{ padding-right: 10px; }
.pr20{ padding-right: 20px; }
.pr30{ padding-right: 30px; }
.pr40{ padding-right: 40px; }
.pr50{ padding-right: 50px; }
.text-center{ text-align: center; }
.text-left{ text-align: left; }
.text-right{ text-align: right; }

._pc{
  display: block;
  @include mq(md) {
    display: none;
  }
}
._sp{
  display: none;
  @include mq(md) {
    display: block;
  }
}