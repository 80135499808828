@charset "UTF-8";


/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;500;600&display=swap");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* iOSでのデフォルトスタイルをリセット */
input[type=submit],
input[type=button] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type=submit]::-webkit-search-decoration,
input[type=button]::-webkit-search-decoration {
  display: none;
}

input[type=submit]::focus,
input[type=button]::focus {
  outline-offset: -2px;
}

.btn-arrow-r {
  display: inline-block;
  text-align: left;
  width: 282px;
  padding-bottom: 14px;
}
.btn-arrow-r-w {
  color: #FFFFFF;
  background: url(../img/arrow_bottom_r_w.png) no-repeat;
  background-position: right bottom;
  background-size: 282px auto;
}
.btn-arrow-r-b {
  color: #000000;
  background: url(../img/arrow_bottom_r_b.png) no-repeat;
  background-position: right bottom;
  background-size: 282px auto;
}
@media screen and (max-width: 768px) {
  .btn-arrow-r {
    width: 227px;
  }
  .btn-arrow-r-w {
    background-size: 227px auto;
  }
}
.btn-square-b {
  background: #000000;
  color: #FFFFFF;
  padding: 20px 40px;
}
@media screen and (max-width: 575px) {
  .btn-square-b {
    font-size: 1.6rem;
  }
}

.gmenu__list__item {
  font-size: 2rem;
  line-height: 30px;
  margin-bottom: 10px;
  font-family: "interstate", sans-serif;
  font-weight: 500;
}
.gmenu__list__item-indent {
  padding-left: 40px;
  font-size: 1.8rem;
}

.sns-list {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 40px;
}
.sns-list__item img {
  vertical-align: middle;
  height: auto;
}
.sns-list__item__x {
  width: 32.45px;
}
.sns-list__item__instagram {
  width: 32.45px;
}
.sns-list__item__note {
  width: 88.46px;
}
.sns-list__item__facebook {
  width: 32.45px;
}
@media screen and (max-width: 575px) {
  .sns-list {
    gap: 0 20px;
  }
}

.wp-pagenavi {
  display: flex;
  justify-content: center;
  line-height: 1.3;
}
.wp-pagenavi .current,
.wp-pagenavi .page,
.wp-pagenavi .previouspostslink,
.wp-pagenavi .nextpostslink {
  font-size: 2rem;
  font-family: "interstate", sans-serif;
  font-weight: 500;
  display: block;
  margin: 0 10px;
}
@media screen and (max-width: 575px) {
  .wp-pagenavi .current,
  .wp-pagenavi .page,
  .wp-pagenavi .previouspostslink,
  .wp-pagenavi .nextpostslink {
    font-size: 1.6rem;
    margin: 0 10px;
  }
}
.wp-pagenavi .current {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  border-bottom: 2px solid #000000;
}
.fadein {
  opacity: 0;
  transform: translate(0, 100px);
  transition: all 1s;
}

.fadein.active {
  opacity: 1;
  transform: translate(0, 0);
}

html,
body {
  font-family: "YakuHanJP", "Noto Sans JP", "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
  font-weight: 500;
  height: 100%;
  line-height: 1.8;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

a {
  color: #000000;
  text-decoration: none;
}

html:before,
html:after,
body:before,
body:after {
  content: "";
  background: #000000;
  position: fixed;
  display: block;
  z-index: 10;
}

html:before {
  height: 10px;
  width: 100vw;
  left: 0;
  top: 0;
}
html:after {
  width: 10px;
  height: 100svh;
  right: 0;
  top: 0;
}

body:before {
  height: 10px;
  width: 100vw;
  bottom: 0;
  left: 0;
}
body:after {
  width: 10px;
  height: 100svh;
  top: 0;
  left: 0;
}

.cts__inner {
  margin: 0 auto;
  max-width: 1076px;
}
@media screen and (max-width: 1119px) {
  .cts__inner {
    padding: 0 30px;
  }
}
.cts__t-space-l {
  padding-top: 100px;
}
.cts__tb-space-l {
  padding-top: 100px;
  padding-bottom: 100px;
}
.cts__t-space-m {
  padding-top: 80px;
}
@media screen and (max-width: 575px) {
  .cts__t-space-m {
    padding-top: 50px;
  }
}
.cts__t-space-m .cts__h2-en {
  margin-bottom: 50px;
}
@media screen and (max-width: 575px) {
  .cts__t-space-m .cts__h2-en {
    margin-bottom: 30px !important;
  }
}
.cts__tb-space-m {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 575px) {
  .cts__tb-space-m {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.cts__tb-space-m .cts__h2-en {
  margin-bottom: 50px;
}
@media screen and (max-width: 575px) {
  .cts__tb-space-m .cts__h2-en {
    margin-bottom: 30px !important;
  }
}
.cts__h2-en {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 6rem;
  margin-bottom: 50px;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .cts__h2-en {
    font-size: 4.4rem;
  }
}
.cts__h2-en span {
  font-size: 2.5rem;
  display: inline-block;
  padding-bottom: 0;
}
@media screen and (max-width: 768px) {
  .cts__h2-en span {
    font-size: 2rem;
    padding-bottom: 0;
  }
}
.cts__h2-en.top-line {
  padding-top: 84px;
  position: relative;
}
.cts__h2-en.top-line:before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.cts__h2-en-info {
  margin-top: -50px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 2.5rem;
}
@media screen and (max-width: 575px) {
  .cts__h2-en-info {
    font-size: 2rem;
    margin-bottom: 50px;
  }
}

.nav {
  width: 376px;
  height: 100%;
  height: 100svh;
  background-color: #000000;
  position: fixed;
  top: 0;
  right: -120%;
  transition: all 0.3s;
  z-index: 15;
}
.nav.panelactive {
  right: 0;
}
.nav__inner {
  position: relative;
  height: 100%;
  height: 100svh;
  color: #FFFFFF;
  padding: 60px;
}
.nav__inner a {
  color: #FFFFFF;
}
.nav__close {
  position: absolute;
  top: 30px;
  right: 36px;
  cursor: pointer;
}
.nav__close img {
  width: 30px;
  height: auto;
}
.nav .gmenu__list__item {
  margin-bottom: 15px;
}
@media screen and (max-width: 575px) {
  .nav .gmenu__list__item {
    margin-bottom: 10px;
  }
}
.nav .sns-list {
  position: absolute;
  bottom: 60px;
  gap: 0 18px;
}
@media screen and (max-width: 575px) {
  .nav .sns-list {
    bottom: 50px;
  }
}

.fix-logo {
  position: fixed;
  top: 30px;
  left: 23px;
  z-index: 5;
}
.fix-logo img {
  width: 60px;
  height: auto;
}
@media screen and (max-width: 575px) {
  .fix-logo {
    top: 18px;
    left: 18px;
  }
  .fix-logo img {
    width: 37px;
  }
}
.fix-ctl {
  position: fixed;
  top: 30px;
  right: 23px;
  z-index: 5;
  width: 85px;
  color: #FFFFFF;
}
@media screen and (max-width: 575px) {
  .fix-ctl {
    top: 18px;
    right: 18px;
    width: 75px;
  }
}
.fix-ctl__menu {
  text-align: center;
  background-color: #D5A026;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
  cursor: pointer;
  line-height: 39px;
}
@media screen and (max-width: 575px) {
  .fix-ctl__menu {
    font-size: 2rem;
    line-height: 32px;
  }
}
.fix-ctl__lang {
  margin-top: 4px;
  background-color: #D5A026;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 7px;
}
@media screen and (max-width: 575px) {
  .fix-ctl__lang {
    display: none;
  }
}
.fix-ctl__lang a {
  display: block;
  text-align: center;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  color: #FFFFFF;
  padding: 0 2px;
  line-height: 20px;
}
.fix-ctl__lang a.active {
  color: #D5A026;
  background-color: #FFFFFF;
}
.fix-ctl .bogo-language-switcher {
  margin-top: 4px;
  background-color: #D5A026;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 7px;
}
@media screen and (max-width: 575px) {
  .fix-ctl .bogo-language-switcher {
    display: none;
  }
}
.fix-ctl .bogo-language-switcher li {
  display: block;
  text-align: center;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  color: #FFFFFF;
  padding: 0 2px;
  line-height: 20px;
}
.fix-ctl .bogo-language-switcher li a {
  color: #FFFFFF;
}
.fix-ctl .bogo-language-switcher li.current {
  background-color: #FFFFFF;
}
.fix-ctl .bogo-language-switcher li.current a {
  color: #D5A026;
}
.fix-shop {
  position: fixed;
  bottom: 30px;
  right: 23px;
  z-index: 5;
}
@media screen and (max-width: 575px) {
  .fix-shop {
    bottom: 13px;
    right: 13px;
  }
}
.fix-shop a {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: #D5A026;
  color: #FFFFFF;
  border-radius: 50px;
  line-height: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .fix-shop a {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
}
.fix-shop__inner {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 2rem;
}
@media screen and (max-width: 575px) {
  .fix-shop__inner {
    font-size: 1.4rem;
  }
}

.news-list__item {
  border-bottom: 1px solid #000000;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .news-list__item {
    margin-bottom: 20px;
  }
}
.news-list__item a {
  display: block;
}
.news-list__item time {
  font-size: 1.4rem;
  font-weight: 300;
}
.news-list__item p {
  font-size: 2rem;
  padding: 18px 0;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .news-list__item p {
    padding: 14px 0 20px;
  }
}
.news-list__link {
  margin-top: 50px;
  text-align: right;
}

.restaurant-list__item {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
@media screen and (max-width: 575px) {
  .restaurant-list__item {
    height: auto;
    padding-top: 100vw;
    background-size: auto 100vw;
    background-position: top center;
  }
}
.restaurant-list__item__inner {
  background-color: #000000;
  color: #FFFFFF;
  padding: 55px;
  position: absolute;
}
.restaurant-list__item__inner a {
  color: #FFFFFF;
}
@media screen and (max-width: 1024px) {
  .restaurant-list__item__inner {
    padding: 30px;
    width: 480px !important;
  }
}
@media screen and (max-width: 575px) {
  .restaurant-list__item__inner {
    position: static;
    width: 100% !important;
    padding: 50px;
  }
}
.restaurant-list__item__ttl {
  font-size: 3rem;
  margin-bottom: 40px;
  line-height: 1.6;
}
@media screen and (max-width: 1024px) {
  .restaurant-list__item__ttl {
    font-size: 2.6rem;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 575px) {
  .restaurant-list__item__ttl {
    font-size: 2.6rem;
    margin-bottom: 25px;
  }
}
.restaurant-list__item__link {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .restaurant-list__item__link {
    margin-top: 25px;
  }
}
@media screen and (max-width: 768px) {
  .restaurant-list__item__link {
    margin-top: 25px;
  }
}
.restaurant-list__item:nth-child(1) {
  background-image: url(../img/bg_restaurant01.jpg);
}
.restaurant-list__item:nth-child(1) .restaurant-list__item__inner {
  width: 515px;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 575px) {
  .restaurant-list__item:nth-child(1) {
    background-image: url(../img/bg_restaurant01@sp.jpg);
  }
}
.restaurant-list__item:nth-child(2) {
  background-image: url(../img/bg_restaurant02.jpg);
}
.restaurant-list__item:nth-child(2) .restaurant-list__item__inner {
  width: 568px;
  left: 0;
  bottom: 0;
}
@media screen and (max-width: 575px) {
  .restaurant-list__item:nth-child(2) {
    background-image: url(../img/bg_restaurant02@sp.jpg);
  }
}
.restaurant-list__item:nth-child(3) {
  background-image: url(../img/bg_restaurant03.jpg);
}
.restaurant-list__item:nth-child(3) .restaurant-list__item__inner {
  width: 571px;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 575px) {
  .restaurant-list__item:nth-child(3) {
    background-image: url(../img/bg_restaurant03@sp.jpg);
  }
}

.available-location__list__item {
  border-bottom: 1px solid #D5A026;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 575px) {
  .available-location__list__item {
    display: block;
  }
}
.available-location__list__item__cate {
  width: 180px;
  color: #D5A026;
}
@media screen and (max-width: 575px) {
  .available-location__list__item__cate {
    width: 100%;
    margin-bottom: 20px;
  }
}
.available-location__list__item__content {
  flex: 1;
}
.available-location__list__item__content__innr {
  display: flex;
}
@media screen and (max-width: 575px) {
  .available-location__list__item__content__innr {
    display: block;
  }
}
.available-location__list__item__area {
  width: 110px;
}
.available-location__list__item__body {
  padding-bottom: 20px;
}
.available-location__list__item__body__innr {
  display: flex;
}
@media screen and (max-width: 575px) {
  .available-location__list__item__body__innr {
    display: block;
  }
}
.available-location__list__item__name {
  padding-right: 40px;
}
.footer {
  background-color: #000000;
  color: #FFFFFF;
}
.footer .cts__inner {
  max-width: 859px;
}
.footer a {
  color: #FFFFFF;
}
.footer__logo {
  text-align: center;
  padding: 80px 0;
}
.footer__logo img {
  width: 203px;
  height: auto;
}
@media screen and (max-width: 575px) {
  .footer__logo img {
    width: 157px;
  }
}
.footer__link {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  margin-bottom: 50px;
}
@media screen and (max-width: 575px) {
  .footer__link {
    display: block;
  }
  .footer__link.cts__inner {
    padding: 0 65px;
  }
}
.footer__sns {
  margin-bottom: 32px;
}
.footer__bottom {
  border-top: 2px solid #FFFFFF;
  padding: 32px 0;
}
@media screen and (max-width: 575px) {
  .footer__bottom {
    border: none;
  }
}
.footer__bottom__list {
  line-height: 1;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .footer__bottom__list {
    flex-wrap: wrap;
  }
}
.footer__bottom__list__item {
  font-family: "interstate", sans-serif;
  font-weight: 500;
  padding: 0 30px;
  border-right: 1px solid #FFFFFF;
}
@media screen and (max-width: 768px) {
  .footer__bottom__list__item {
    font-size: 1.4rem;
  }
}
.footer__bottom__list__item:first-child {
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .footer__bottom__list__item:first-child {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    padding: 0;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .footer__bottom__list__item:nth-child(2) {
    padding-left: 0;
  }
}
.footer__bottom__list__item:last-child {
  padding-right: 0;
  border: none;
}

.top-mv {
  height: 100%;
  height: 100svh;
  position: relative;
  background-image: url(../img/mv.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 575px) {
  .top-mv {
    background-image: url(../img/mv@sp.jpg);
  }
}
.top-mv__cts {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .top-mv__cts {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.top-mv__cts__logo {
  margin-bottom: 2.9282576867vw;
}
.top-mv__cts__logo img {
  width: 340px;
  height: auto;
}
@media screen and (max-width: 575px) {
  .top-mv__cts__logo {
    margin-bottom: 17.3333333333vw;
  }
  .top-mv__cts__logo img {
    width: 227px;
  }
}
.top-mv__cts__txt {
  font-size: 6rem;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .top-mv__cts__txt {
    font-size: 5rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 575px) {
  .top-mv__cts__txt {
    font-size: 3.5rem;
  }
}
.top-lead {
  background-color: #000000;
  color: #FFFFFF;
  padding: 80px 0;
}
.top-lead__ttl {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 7.3rem;
  line-height: 1.1428571429;
}
@media screen and (max-width: 575px) {
  .top-lead__ttl {
    font-size: 3rem;
  }
}
.top-lead__txt {
  font-size: 2rem;
  margin: 60px 0;
  line-height: 2;
}
@media screen and (max-width: 575px) {
  .top-lead__txt {
    font-size: 1.8rem;
    margin: 50px 0;
    line-height: 2.1111111111;
  }
}
.top-lead__link {
  text-align: right;
}
.top-recruit {
  background-color: #F2F3F4;
}
.top-recruit__img {
  display: flex;
  justify-content: space-between;
}
.top-recruit__img img {
  width: 49%;
}
@media screen and (max-width: 575px) {
  .top-recruit__img {
    flex-direction: column;
    gap: 10px 0;
  }
  .top-recruit__img img {
    width: 100%;
  }
}
.top-recruit__link {
  text-align: right;
  margin-top: 60px;
}
@media screen and (max-width: 575px) {
  .top-recruit__link {
    margin-top: 40px;
  }
}
.top-access {
  background-color: #F2F3F4;
}
.top-access__col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 60px;
}
@media screen and (max-width: 1024px) {
  .top-access__col {
    gap: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .top-access__col {
    display: block;
  }
}
.top-access__map {
  border: 1px solid #ccc;
}
.top-access__map iframe {
  width: 627px;
  height: 390px;
  -webkit-filter: saturate(150%);
  filter: saturate(150%);
}
@media screen and (max-width: 1024px) {
  .top-access__map iframe {
    width: 500px;
  }
}
@media screen and (max-width: 768px) {
  .top-access__map iframe {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .top-access__map {
    margin-bottom: 30px;
  }
}
.top-access__data {
  flex: 1;
}
.top-access__data__address {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .top-access__data__address {
    margin-bottom: 20px;
  }
}
.top-access__data > dl > dt {
  font-size: 2.5rem;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .top-access__data > dl > dt {
    font-size: 2rem;
  }
}
.top-access__data > dl > dd {
  font-size: 2rem;
}
@media screen and (max-width: 768px) {
  .top-access__data > dl > dd {
    font-size: 1.6rem;
  }
}
.top-access__data > dl > dd > dl {
  display: flex;
  margin-bottom: 3px;
}
.top-access__data > dl > dd > dl > dt {
  width: 120px;
}
.top-access__data > dl > dd > dl > dd {
  width: calc(100% - 120px);
}
.top-access__data > dl > dd > dl > dd span {
  font-size: 90%;
}
.top-contact {
  margin-top: 160px;
  color: #FFFFFF;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .top-contact {
    margin-top: 100px;
  }
}
.top-contact .cts__inner {
  padding: 60px 0;
  background-color: #000000;
}
@media screen and (max-width: 1024px) {
  .top-contact .cts__inner {
    margin: 0 30px;
  }
}
@media screen and (max-width: 575px) {
  .top-contact .cts__inner {
    padding: 40px 0;
  }
}
.top-contact__ttl {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 4.5rem;
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .top-contact__ttl {
    margin-bottom: 20px;
  }
}
.top-contact__txt {
  font-size: 2rem;
  margin-bottom: 40px;
}
@media screen and (max-width: 575px) {
  .top-contact__txt {
    font-size: 1.6rem;
  }
}
.top-contact__link {
  text-align: center;
}
.top-contact__link a {
  display: inline-block;
  width: 236px;
  line-height: 56px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
@media screen and (max-width: 575px) {
  .top-contact__link a {
    width: 211px;
  }
}
.top-gallery-middle {
  padding: 100px 0;
}
.top-gallery-middle .cts__inner {
  position: relative;
  height: 820px;
}
@media screen and (max-width: 1024px) {
  .top-gallery-middle .cts__inner {
    width: 78.7701317716vw;
    height: 60.0292825769vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery-middle .cts__inner {
    width: auto;
    height: 780px;
  }
}
.top-gallery-middle img {
  position: absolute;
  height: auto;
}
.top-gallery-bottom {
  position: relative;
  height: 1188px;
}
.top-gallery-bottom img {
  position: absolute;
  height: auto;
}
@media screen and (max-width: 1024px) {
  .top-gallery-bottom {
    height: 86.9692532943vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery-bottom {
    height: 900px;
  }
}
.top-gallery__txt01 {
  width: 172px;
  top: 420px;
  left: 44px;
}
@media screen and (max-width: 1024px) {
  .top-gallery__txt01 {
    width: 12.5915080527vw;
    top: 30.7467057101vw;
    left: 3.2210834553vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery__txt01 {
    display: none;
  }
}
.top-gallery__img01 {
  width: 538px;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1024px) {
  .top-gallery__img01 {
    width: 39.3850658858vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery__img01 {
    width: 280px;
  }
}
.top-gallery__img02 {
  width: 458px;
  top: 105px;
  right: 0;
}
@media screen and (max-width: 1024px) {
  .top-gallery__img02 {
    width: 33.5285505124vw;
    top: 7.6866764275vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery__img02 {
    width: 238px;
    top: 209px;
  }
}
.top-gallery__img03 {
  width: 342px;
  left: 196px;
  bottom: 0;
}
@media screen and (max-width: 1024px) {
  .top-gallery__img03 {
    width: 25.0366032211vw;
    left: 14.3484626647vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery__img03 {
    width: 238px;
    left: 30px;
    bottom: 0;
  }
}
.top-gallery__img04 {
  width: 714px;
  top: 100px;
  left: 50%;
  margin-left: -357px;
}
@media screen and (max-width: 1024px) {
  .top-gallery__img04 {
    width: 52.2693997072vw;
    top: 7.3206442167vw;
    margin-left: -26.1346998536vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery__img04 {
    width: 256px;
    top: 100px;
    left: 0;
    margin-left: 0;
    transform: translate(0, 0);
  }
}
.top-gallery__img05 {
  width: 538px;
  bottom: 199px;
  left: -80px;
}
@media screen and (max-width: 1024px) {
  .top-gallery__img05 {
    width: 39.3850658858vw;
    bottom: 14.5680819912vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery__img05 {
    width: 256px;
    bottom: 396px;
    left: auto;
    right: 0;
  }
}
.top-gallery__img06 {
  width: 634px;
  bottom: 125px;
  right: 0;
}
@media screen and (max-width: 1024px) {
  .top-gallery__img06 {
    width: 46.4128843338vw;
    bottom: 9.1508052709vw;
  }
}
@media screen and (max-width: 575px) {
  .top-gallery__img06 {
    width: 300px;
    bottom: 100px;
    left: 30px;
    right: auto;
  }
}
.top-beer {
  margin-bottom: 100px;
}
.top-beer__head {
  height: 640px;
  position: relative;
  background-image: url(../img/bg_beer01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 80px;
}
@media screen and (max-width: 575px) {
  .top-beer__head {
    background-image: url(../img/bg_beer01@sp.jpg);
    margin-bottom: 50px;
  }
}
.top-beer__head__ttl {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
.top-beer__list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 30px;
}
@media screen and (max-width: 575px) {
  .top-beer__list {
    width: 100%;
  }
}
.top-beer__list__item {
  width: calc((100% - 60px) / 3);
}
@media screen and (max-width: 768px) {
  .top-beer__list__item {
    width: calc((100% - 30px) / 2);
  }
}
@media screen and (max-width: 575px) {
  .top-beer__list__item {
    width: 100%;
  }
}
.top-beer__list__item__th img {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.top-beer__list__item__name {
  margin-top: 10px;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}
.top-beer__list__item__name span {
  font-size: 1.6rem;
}
.top-beer__link {
  text-align: right;
  margin-top: 60px;
}
.top-beer-line {
  height: 570px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .top-beer-line {
    height: 460px;
  }
}
@media screen and (max-width: 575px) {
  .top-beer-line {
    height: 325px;
  }
}
.top-beer-line__img {
  width: 154px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .top-beer-line__img {
    width: 130px;
  }
}
@media screen and (max-width: 575px) {
  .top-beer-line__img {
    width: 87px;
  }
}
.top-beer-line__wrap {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 1;
}
.top-beer-line__wrap__inner {
  width: 100%;
}
.top-beer-line__txt {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 10rem;
  display: flex;
}
@media screen and (max-width: 768px) {
  .top-beer-line__txt {
    font-size: 6rem;
  }
}
@media screen and (max-width: 575px) {
  .top-beer-line__txt {
    font-size: 4rem;
  }
}
.top-beer-line__txt div {
  flex: 0 0 auto;
  white-space: nowrap;
  padding: 0 40px;
}
.top-beer-line__txt .loop1 {
  animation: beer-txt-loop 30s linear infinite;
}
.top-beer-line__txt .loop2 {
  position: absolute;
  top: 0;
  left: 0;
  animation: beer-txt-loop 30s -15s linear infinite;
}
@keyframes beer-txt-loop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.top-house {
  background-color: #000000;
  padding: 80px 0;
}
@media screen and (max-width: 575px) {
  .top-house {
    margin-bottom: 60px;
  }
}
.top-house__ttl {
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 80px;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}
@media screen and (max-width: 575px) {
  .top-house__ttl {
    margin-bottom: 50px;
    font-size: 2rem;
  }
}
.top-house__slide {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .top-house__slide {
    width: 95%;
  }
}
.top-house__slide__inner {
  width: 100%;
  overflow: hidden;
}
.top-house__slide .swiper-slide {
  width: 100%;
  aspect-ratio: 331/561;
  background-color: #DDD;
}
.top-house__slide .swiper-slide img {
  object-fit: cover;
  vertical-align: bottom;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 575px) {
  .top-house__slide .swiper-slide {
    aspect-ratio: 300/541;
  }
}
.top-house__slide__next, .top-house__slide__prev {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 3;
  cursor: pointer;
}
.top-house__slide__next img, .top-house__slide__prev img {
  width: 28px;
  height: auto;
}
.top-house__slide__prev {
  left: -14px;
}
.top-house__slide__next {
  right: -14px;
}
.top-local {
  margin-bottom: 100px;
  overflow: hidden;
}
@media screen and (max-width: 575px) {
  .top-local {
    margin-bottom: 60px;
  }
}
.top-local__slide {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.top-local__slide .swiper-slide {
  width: 100%;
  aspect-ratio: 1/1;
  padding-bottom: 95px;
}
.top-local__slide .swiper-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.top-local__slide__txt {
  position: absolute;
  margin: 0 30px;
  padding: 15px;
  bottom: 0;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  z-index: 4;
}
.top-local__slide__next, .top-local__slide__prev {
  position: absolute;
  bottom: 40px;
  z-index: 3;
  cursor: pointer;
}
.top-local__slide__next img, .top-local__slide__prev img {
  width: 14px;
  height: auto;
}
.top-local__slide__prev {
  left: 25%;
}
.top-local__slide__next {
  right: 25%;
}
@media screen and (max-width: 768px) {
  .top-local__slide__prev {
    left: 8%;
  }
  .top-local__slide__next {
    right: 8%;
  }
}
@media screen and (max-width: 575px) {
  .top-local__slide__prev {
    left: 2.5%;
  }
  .top-local__slide__next {
    right: 2.5%;
  }
}

.mv {
  height: 566px;
  margin-bottom: 122px;
  position: relative;
  background-color: #F2F3F4;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 575px) {
  .mv {
    height: auto;
    margin-bottom: 60px;
    background-position: center top;
    background-size: auto 100vw;
  }
}
.mv.about {
  background-image: url(../img/bg_about.jpg);
}
@media screen and (max-width: 575px) {
  .mv.about {
    background-image: url(../img/bg_about@sp.jpg);
  }
}
.mv.beer {
  background-image: url(../img/bg_beer.jpg);
}
@media screen and (max-width: 575px) {
  .mv.beer {
    background-image: url(../img/bg_beer@sp.jpg);
  }
}
.mv.restaurant {
  background-image: url(../img/bg_restaurant.jpg);
}
@media screen and (max-width: 575px) {
  .mv.restaurant {
    background-image: url(../img/bg_restaurant@sp.jpg);
  }
}
.mv__inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  width: 90%;
}
@media screen and (max-width: 575px) {
  .mv__inner {
    position: static;
    transform: translate(0, 0);
    width: 100%;
  }
}
.mv__ttl {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 6rem;
}
@media screen and (max-width: 575px) {
  .mv__ttl {
    position: static;
    transform: translate(0, 0);
    text-align: center;
    font-size: 4.6rem;
    line-height: 100vw;
  }
}
.mv__txt {
  font-size: 2.5rem;
}
@media screen and (max-width: 575px) {
  .mv__txt {
    color: #000000;
    font-size: 2rem;
    padding: 30px 30px 0;
    background-color: #FFFFFF;
  }
}

.page .team {
  margin-bottom: 85px;
}
@media screen and (max-width: 768px) {
  .page .team {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .page .team .cts__inner {
    padding: 0;
  }
}
.page .team-list__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
  .page .team-list__item {
    margin-bottom: 50px;
  }
}
.page .team-list__item__th {
  width: 565px;
  margin-right: 80px;
}
.page .team-list__item__th img {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .page .team-list__item__th {
    width: 480px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .page .team-list__item__th {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.page .team-list__item__data {
  flex: 1;
}
@media screen and (max-width: 768px) {
  .page .team-list__item__data {
    padding: 0 30px;
  }
}
.page .team-list__item__data__name {
  font-size: 2.5rem;
  margin: 10px 0;
}
@media screen and (max-width: 768px) {
  .page .team-list__item__data__name {
    font-size: 2rem;
    margin: 10px 0;
  }
}
@media screen and (max-width: 768px) {
  .page .history .cts__inner {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .page .history__cts-wrap .history__cts:nth-child(even) {
    flex-direction: column-reverse;
  }
}
.page .history__cts {
  display: flex;
  flex-wrap: wrap;
  gap: 0 33px;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .page .history__cts {
    margin-bottom: 60px;
  }
}
.page .history__cts__th {
  width: 538px;
}
.page .history__cts__th img {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .page .history__cts__th {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .page .history__cts__th {
    width: 100%;
    margin-bottom: 30px;
  }
}
.page .history__cts__txt {
  flex: 1;
}
@media screen and (max-width: 768px) {
  .page .history__cts__txt {
    width: 100%;
    padding: 0 30px;
  }
}
.page .menu__wrap {
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  gap: 0 2%;
  word-wrap: break-all;
}
@media screen and (max-width: 575px) {
  .page .menu__wrap {
    margin-bottom: 60px;
  }
}
.page .menu__col__full {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 45px;
}
.page .menu__col__half {
  width: 48%;
  margin: 25px 0;
}
@media screen and (max-width: 768px) {
  .page .menu__col__half {
    margin: 15px 0;
  }
}
@media screen and (max-width: 575px) {
  .page .menu__col__half {
    width: 100%;
  }
}
.page .menu__col__th img {
  object-fit: cover;
  width: 537px;
  height: 325px;
}
@media screen and (max-width: 1024px) {
  .page .menu__col__th img {
    width: 480px;
    aspect-ratio: 8/5;
  }
}
@media screen and (max-width: 768px) {
  .page .menu__col__th {
    margin-bottom: 10px;
  }
  .page .menu__col__th img {
    width: 100%;
  }
}
.page .menu__col__data {
  flex: 1;
  font-family: "interstate", sans-serif;
  font-weight: 700;
}
.page .menu__col__data__name {
  font-size: 2.5rem;
  margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
  .page .menu__col__data__name {
    font-size: 2rem;
  }
}
.page .menu__col__data__price {
  font-size: 2.5rem;
}
@media screen and (max-width: 768px) {
  .page .menu__col__data__price {
    font-size: 2rem;
  }
}
.page .menu__col__data__txt {
  margin-top: 10px;
}
@media screen and (max-width: 575px) {
  .page .menu__col__data__txt {
    margin-top: 0;
  }
}
.page .menu__head {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  margin-bottom: 25px;
}
.page .menu__head__ttl {
  padding-left: 16px;
  border-left: solid 17px #000000;
  font-size: 3.5rem;
  margin-bottom: 10px;
  line-height: 1.2;
}
@media screen and (max-width: 575px) {
  .page .menu__head__ttl {
    padding-left: 10px;
    border-left: solid 14px #000000;
    font-size: 2.5rem;
  }
}
.page .party {
  text-align: center;
}
.page .party__img {
  margin-bottom: 50px;
}
.page .party__img img {
  width: 100%;
}
.page .party__txt01 {
  font-size: 3.5rem;
  margin-bottom: 40px;
}
@media screen and (max-width: 575px) {
  .page .party__txt01 {
    margin-bottom: 20px;
    font-size: 2.5rem;
  }
}
.page .party__txt02 {
  font-size: 2rem;
  margin-bottom: 40px;
}
@media screen and (max-width: 575px) {
  .page .party__txt02 {
    margin-bottom: 20px;
    text-align: left;
    font-size: 2rem;
  }
}
.page .party__txt03 {
  font-size: 2.5rem;
}
.page .contact {
  margin-bottom: 100px;
}
.page .contact.head {
  padding-top: 85px;
}
@media screen and (max-width: 575px) {
  .page .contact.head {
    padding-top: 100px;
  }
}
.page .contact__body {
  font-size: 2rem;
}
.page .contact__info {
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .page .contact__info {
    text-align: left;
  }
}
.page .contact__info__txt {
  margin-bottom: 2rem;
  word-break: break-all;
}
.page .contact__info a:hover {
  text-decoration: underline;
}
.page .contact__form {
  border: 1px solid #707070;
  border-radius: 20px;
  padding: 80px 80px 30px;
}
@media screen and (max-width: 768px) {
  .page .contact__form {
    padding: 60px 30px 30px;
  }
}
.page .contact__form dl {
  display: flex;
  flex-wrap: wrap;
}
.page .contact__form dl dt {
  width: 268px;
  padding: 5px 0;
}
.page .contact__form dl dd {
  width: calc(100% - 268px);
  margin-bottom: 50px;
}
.page .contact__form dl dd input, .page .contact__form dl dd textarea {
  width: 100%;
  padding: 5px 10px;
}
.page .contact__form dl dd textarea {
  height: 346px;
}
.page .contact__form dl dd:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .page .contact__form dl {
    display: block;
  }
  .page .contact__form dl dt, .page .contact__form dl dd {
    width: 100%;
  }
  .page .contact__form dl dt {
    margin-bottom: 5px;
  }
  .page .contact__form dl dd {
    margin-bottom: 20px;
  }
}
.page .contact__submit {
  text-align: center;
  margin-top: 90px;
}
@media screen and (max-width: 575px) {
  .page .contact__submit {
    margin-top: 40px;
  }
}
.page .contact__submit__col {
  margin-bottom: 30px;
}
.page .contact__submit__col a {
  text-decoration: underline;
}
@media screen and (max-width: 575px) {
  .page .contact__submit__col {
    font-size: 1.4rem;
    margin-bottom: 40px;
  }
}
.page .contact input[type=text], .page .contact input[type=tel], .page .contact input[type=email], .page .contact textarea {
  border: 1px solid #707070;
  border-radius: 12px;
  font-size: 2rem;
  padding: 10px 20px;
}
.page .contact__thanks__txt {
  text-align: center;
  margin: 95px 0;
}
@media screen and (max-width: 575px) {
  .page .contact__thanks__txt {
    font-size: 1.6rem;
    margin: 75px 0;
  }
}
.page .contact__thanks__logo {
  text-align: center;
  margin-left: 110px;
}
.page .contact__thanks__logo img {
  width: 260px;
}
@media screen and (max-width: 575px) {
  .page .contact__thanks__logo {
    margin-left: 50px;
  }
  .page .contact__thanks__logo img {
    width: 170px;
  }
}

.error404 .cts.head {
  padding-top: 85px;
}
@media screen and (max-width: 575px) {
  .error404 .cts.head {
    padding-top: 100px;
  }
}

.news.head {
  padding-top: 85px;
}
@media screen and (max-width: 575px) {
  .news.head {
    padding-top: 100px;
  }
}
.news__article {
  margin-bottom: 180px;
}
@media screen and (max-width: 768px) {
  .news__article {
    margin-bottom: 80px;
  }
}
.news__article__head {
  font-family: "interstate", sans-serif;
  font-weight: 700;
}
.news__article__time {
  font-size: 2.5rem;
}
@media screen and (max-width: 575px) {
  .news__article__time {
    font-size: 2rem;
  }
}
.news__article__ttl {
  font-size: 3.5rem;
  margin: 60px 0;
}
@media screen and (max-width: 768px) {
  .news__article__ttl {
    margin: 40px 0;
  }
}
@media screen and (max-width: 575px) {
  .news__article__ttl {
    font-size: 2.5rem;
    margin: 30px 0;
  }
}
.news__article__th {
  margin-bottom: 50px;
}
.news__article__th img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .news__article__th {
    margin-bottom: 30px;
  }
}
.news__article__body p {
  margin-bottom: 2rem;
}

.beer-list__item {
  display: flex;
  flex-wrap: wrap;
  gap: 0 35px;
  margin-bottom: 130px;
}
.beer-list__item__th {
  width: 547px;
}
.beer-list__item__th img {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .beer-list__item__th {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .beer-list__item__th {
    width: 300px;
  }
}
@media screen and (max-width: 575px) {
  .beer-list__item__th {
    width: 100%;
  }
}
.beer-list__item__data {
  flex: 1;
}
.beer-list__item__data__ttl {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  margin-bottom: 36px;
}
@media screen and (max-width: 768px) {
  .beer-list__item__data__ttl {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 575px) {
  .beer-list__item__data__ttl {
    font-size: 2.5rem;
    margin: 15px 0;
  }
}
.beer-list__item__data__txt {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .beer-list__item__data__txt {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px) {
  .beer-list__item__data__txt {
    margin-bottom: 30px;
  }
}
.beer-list__item__data__detail {
  border: 1px solid #000000;
}
.beer-list__item__data__detail-btn {
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 575px) {
  .beer-list__item__data__detail-btn {
    display: block;
    text-align: center;
    line-height: 30px;
  }
}
.beer-list__item__data__detail__inner {
  padding: 16px;
}
@media screen and (max-width: 575px) {
  .beer-list__item__data__detail__inner {
    display: none;
  }
}
.beer-list__item__data__detail__inner dl {
  display: flex;
  flex-wrap: wrap;
}
.beer-list__item__data__detail__inner dl dt {
  width: 175px;
}
@media screen and (max-width: 575px) {
  .beer-list__item__data__detail__inner dl dt {
    width: 100%;
  }
}
.beer-list__item__data__detail__inner dl dd {
  padding-left: 10px;
  margin-bottom: 3px;
  width: calc(100% - 175px);
}
@media screen and (max-width: 575px) {
  .beer-list__item__data__detail__inner dl dd {
    width: 100%;
  }
}
.beer-list__item__data__link {
  margin-top: 40px;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .beer-list__item__data__link a {
    width: 280px;
  }
}

.wp-block-heading {
  margin: 30px 0 10px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb100 {
  margin-bottom: 100px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pl50 {
  padding-left: 50px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

._pc {
  display: block;
}
@media screen and (max-width: 768px) {
  ._pc {
    display: none;
  }
}

._sp {
  display: none;
}
@media screen and (max-width: 768px) {
  ._sp {
    display: block;
  }
}